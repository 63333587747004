import React from 'react';

import MobileLayer from './Mobile.js';
import TabletLayer from './Tablet.js';
import LaptopLayer from './Laptop.js';
import DesktopLayer from './Desktop.js';

/* eslint-disable */
const LayerMouse = ({}) => {
	return (
		<>
			<MobileLayer />
			<TabletLayer />
			<LaptopLayer />
			<DesktopLayer />
		</>
	);
};

export default LayerMouse;
