import React from 'react';
import CultureFrame from '@components/organisms/CultureFrame';
// layers
import LayerTop from '@components/assets/Culture/EN/LayerTop';
import LayerBottom from '@components/assets/Culture/EN/LayerBottom';
import LayerMouse from '@components/assets/Culture/EN/LayerMouse';

// components
import Layout from '@src/layouts';

// --------------------------------
// #region data
// --------------------------------

const pageClass = 'page-culture';

const language = 'en';

const footerRelatedLinks = [
	{ title: 'Contact us', url: '/en/contact/' },
	// { title: 'Jobs', url: '/en/jobs/' },
];

// --------------------------------
// #endregion
// --------------------------------

const CulturePage = (props) => {
	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/fr/culture/', lang: 'fr' }]}
			title="Our culture - Superhuit"
			type="culture"
		>
			<div className="visually-hidden">
				<h1>Culture</h1>
				<h2>1. Promote self-growth</h2>
				<p>It all starts with a nurturing environment that empowers our people. This is how we succeed together.</p>
				<h2>2. Unleash creativity</h2>
				<p>We're all ears to your best ideas, no matter your title.</p>
				<h2>3. We love Mondays</h2>
				<p>Passion drives us – and the moment it doesn't, we have something to fix.</p>
				<h2>4. Shake, shake, shake it up</h2>
				<p>Question the status quo. Change is always positive. Shake it up!</p>
				<h2>5. Better together</h2>
				<p>Collaborative intelligence drives us to constantly progress. It's our key to success!</p>
				<h2>6. Transparency is relevant</h2>
				<p>Transparency empowers teams with shared leadership to take better decisions.</p>
				<h2>7. People are trustworthy</h2>
				<p>Trust expands talent: it should be given, not earned.</p>
				<h2>8. Risk enables progress</h2>
				<p>Innovation and growth are born out of risk. We are explorers seeking adventure.</p>
			</div>
			<CultureFrame
				top={<LayerTop />}
				bottom={<LayerBottom />}
				mouse={<LayerMouse />}
				hint={
					<>
						Play with
						<br />
						the circle
					</>
				}
			/>
		</Layout>
	);
};

export default CulturePage;
