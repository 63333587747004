import React from 'react';

import MobileLayer from './Mobile.js';
import TabletLayer from './Tablet.js';
import LaptopLayer from './Laptop.js';
import DesktopLayer from './Desktop.js';

/* eslint-disable */
const LayerBottom = ({}) => {
	return (
		<>
			{/* defs */}
			<defs>
				<radialGradient cx="83.495%" cy="100%" fx="83.495%" fy="100%" r="141.212%" gradientTransform="matrix(-.17474 -.67785 .98462 -.1203 -.004 1.686)" id="bottom-a"><stop stopColor="red" offset="0%"/><stop stopColor="#434343" offset="100%"/></radialGradient>
				<radialGradient cx="10.872%" cy="-11.673%" fx="10.872%" fy="-11.673%" r="159.332%" gradientTransform="matrix(.16003 .64643 -.98711 .1048 -.024 -.175)" id="bottom-b"><stop stopColor="red" offset="0%"/><stop stopColor="#434343" offset="100%"/></radialGradient>
			</defs>
			<MobileLayer />
			<TabletLayer />
			<LaptopLayer />
			<DesktopLayer />
		</>
	);
};

export default LayerBottom;
