import React from 'react';

import MobileLayer from './Mobile.js';
import TabletLayer from './Tablet.js';
import LaptopLayer from './Laptop.js';
import DesktopLayer from './Desktop.js';

/* eslint-disable */
const LayerTop = ({}) => {
	return (
		<>
		{/* defs */}
		<defs>
			<radialGradient cx="142.817%" cy="-5.198%" fx="142.817%" fy="-5.198%" r="111.743%" gradientTransform="scale(-1 -.47521) rotate(-88.183 .083 1.503)" id="top-a"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="-43.368%" cy="-19.569%" fx="-43.368%" fy="-19.569%" r="114.613%" gradientTransform="matrix(0 .79193 -1 0 -.63 .148)" id="top-b"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="-7.528%" cy="0%" fx="-7.528%" fy="0%" r="104.713%" gradientTransform="matrix(0 1 -.79516 0 -.075 .075)" id="top-c"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="72.614%" cy="-34.661%" fx="72.614%" fy="-34.661%" r="122.858%" gradientTransform="matrix(0 1 -.41128 0 .584 -1.073)" id="top-d"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="-104.164%" cy="-22.373%" fx="-104.164%" fy="-22.373%" r="160.944%" gradientTransform="matrix(0 .9432 -1 0 -1.265 .759)" id="top-e"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="77.055%" cy="-28.757%" fx="77.055%" fy="-28.757%" r="89.818%" gradientTransform="scale(.80534 1) rotate(88.544 .864 -.192)" id="top-f"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="-124.615%" cy="-78.859%" fx="-124.615%" fy="-78.859%" r="241.337%" gradientTransform="matrix(0 .76792 -1 0 -2.035 .168)" id="top-g"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="156.77%" cy="50%" fx="156.77%" fy="50%" r="195.387%" gradientTransform="matrix(0 1 -.61501 0 1.875 -1.068)" id="top-h"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="-51.202%" cy="78.301%" fx="-51.202%" fy="78.301%" r="92.728%" gradientTransform="matrix(0 .8425 -1 0 .271 1.214)" id="top-i"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="149.082%" cy="0%" fx="149.082%" fy="0%" r="139.274%" gradientTransform="matrix(0 1 -.75423 0 1.49 -1.49)" id="top-j"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="-35.016%" cy="21.141%" fx="-35.016%" fy="21.141%" r="104.713%" gradientTransform="matrix(0 1 -.91262 0 -.157 .562)" id="top-k"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="-67.772%" cy="68.339%" fx="-67.772%" fy="68.339%" r="151.343%" gradientTransform="matrix(0 .6919 -1 0 .006 1.152)" id="top-l"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="173.632%" cy="0%" fx="173.632%" fy="0%" r="137.052%" gradientTransform="scale(1 .66037) rotate(90 1.736 0)" id="top-m"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="71.508%" cy="183.873%" fx="71.508%" fy="183.873%" r="133.701%" gradientTransform="matrix(0 1 -.8671 0 2.31 1.124)" id="top-n"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="28.647%" cy="-47.117%" fx="28.647%" fy="-47.117%" r="128.335%" gradientTransform="matrix(0 .81593 -1 0 -.185 -.705)" id="top-o"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="-37.294%" cy="0%" fx="-37.294%" fy="0%" r="104.713%" gradientTransform="matrix(0 1 -.96 0 -.373 .373)" id="top-p"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="149.855%" cy="89.465%" fx="149.855%" fy="89.465%" r="170.15%" gradientTransform="matrix(0 1 -.70806 0 2.132 -.604)" id="top-q"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="-53.382%" cy="73.112%" fx="-53.382%" fy="73.112%" r="124.013%" gradientTransform="matrix(0 .84437 -1 0 .197 1.182)" id="top-r"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="-117.089%" cy="-37.972%" fx="-117.089%" fy="-37.972%" r="184.803%" gradientTransform="scale(.95578 1) rotate(58.616 -1.198 -.428)" id="top-s"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="-100.424%" cy="-55.219%" fx="-100.424%" fy="-55.219%" r="198.803%" gradientTransform="scale(1 .68023) rotate(28.217 -.488 -.682)" id="top-t"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="#C50000" offset="100%"/></radialGradient>
			<radialGradient cx="71.368%" cy="27.165%" fx="71.368%" fy="27.165%" r="404.162%" gradientTransform="matrix(.05287 .644 -.9986 .0341 .947 -.197)" id="top-u"><stop stopColor="#C50000" offset="0%"/><stop stopColor="#1F1F1F" offset="100%"/></radialGradient>
			<radialGradient cx="134.35%" cy="17.292%" fx="134.35%" fy="17.292%" r="104.713%" gradientTransform="matrix(0 1 -.73588 0 1.47 -1.17)" id="top-v"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
			<radialGradient cx="-50.036%" cy="71.578%" fx="-50.036%" fy="71.578%" r="78.124%" gradientTransform="matrix(0 1 -1.1896 0 .351 1.216)" id="top-w"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="59.734%" cy="139.995%" fx="59.734%" fy="139.995%" r="95.829%" gradientTransform="matrix(0 .81524 -1 0 1.997 .913)" id="top-x"><stop stopColor="#1F1F1F" offset="0%"/><stop stopColor="red" offset="100%"/></radialGradient>
			<radialGradient cx="83.495%" cy="100%" fx="83.495%" fy="100%" r="141.212%" gradientTransform="matrix(-.17474 -.67785 .98462 -.1203 -.004 1.686)" id="top-y"><stop stopColor="red" offset="0%"/><stop stopColor="#434343" offset="100%"/></radialGradient>
			<radialGradient cx="10.872%" cy="-11.673%" fx="10.872%" fy="-11.673%" r="159.332%" gradientTransform="matrix(.16003 .64643 -.98711 .1048 -.024 -.175)" id="top-z"><stop stopColor="red" offset="0%"/><stop stopColor="#434343" offset="100%"/></radialGradient>
			<radialGradient cx="-22.697%" cy="124.294%" fx="-22.697%" fy="124.294%" r="340.939%" gradientTransform="scale(.20782 1) rotate(82.91 -.66 .753)" id="top-A"><stop stopColor="red" offset="0%"/><stop stopColor="#202020" offset="100%"/></radialGradient>
		</defs>
		<MobileLayer />
		<TabletLayer />
		<LaptopLayer />
		<DesktopLayer />
		</>
	);
};

export default LayerTop;
